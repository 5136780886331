import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios
    .get(`${base_url}permissions`, {params})
    .then((response) => {
      return response.data;
    });
}

async function productTiersOption(params) {
  return axios
    .get(`${base_url}product-tiers`, {params})
    .then((response) => {
      return response.data;
    });
}

async function productTiersPermission(params) {
  return axios
    .get(`${base_url}product-tier-permissions`, {params})
    .then((response) => {
      return response.data;
    });
}

// Url  : product-tier-permissions?product_tier_id=1
// Method : Get

export default {
  list,
  productTiersOption,
  productTiersPermission
};
