import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios
    .get(`${base_url}telematic-details`, { params })
    .then((response) => {
      return response.data;
    });
}
async function OEMlist(params) {
  return axios
    .get(`${base_url}oem-telematic-details`, { params })
    .then((response) => {
      return response.data;
    });
}

async function commandsList(imei, params) {
  return axios
    .get(`${base_url}flespi-commands/${imei}`, { params })
    .then((response) => {
      return response.data;
    });
}

function show(id) {
  return axios.get(`${base_url}telematic-details/${id}`).then((response) => {
    return response.data;
  });
}

async function getHeadersList(clientId) {
  return axios
    .get(`${base_url}telematic-details/${clientId}/headers-list`)
    .then((response) => {
      return response.data;
    });
}

async function vehicleTelematicDashboard(params) {
  return axios
    .get(`${base_url}vehicle-dashboard`, { params })
    .then((response) => {
      return response.data;
    });
}

export default {
  list,
  OEMlist,
  getHeadersList,
  commandsList,
  show,
  vehicleTelematicDashboard,
};
