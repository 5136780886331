<template>
  <v-btn
    class="reload-btn"
    :class="isLoading ? 'active' : ''"
    outlined
    small
    @click="reload"
  >
    <v-icon>mdi-reload</v-icon>
  </v-btn>
  <!-- <div
    class="reload-btn"
    :class="isLoading ? 'active' : ''"
  >
    <v-icon @click="reload">
      mdi-reload
    </v-icon>
  </div> -->
</template>
<script>
export default {
  name: "RefreshButton",
  props: {
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    reload() {
      this.$emit("reload");
    },
  },
};
</script>
<style scoped>
/* .reload-btn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
} */

/* .reload-btn i,
.reload-btn button {
  cursor: pointer;
  color: #20a390 !important;
} */

.active button,
.active i {
  animation: spin 0.9s infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
