import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios.get(`${base_url}tenants`, { params }).then((response) => {
    return response.data;
  });
}

async function add(params) {
  return axios
    .post(`${base_url}tenants`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    });
}

async function update(params) {
  return axios
    .post(`${base_url}tenants/${params.id}`, {...params,"_method":"put"}, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    });
}

async function destroy(params) {
  return axios
    .delete_method(`${base_url}tenants/${params.id}`)
    .then((response) => {
      return response.data;
    });
}

export default {
  list,
  add,
  update,
  destroy,
};
