import service from "../services/tenants-management-service";

const state = {
  isLoading: false,
  meta: {},
  tenantsList: [],
};

const mutations = {
  SET_TENANTS_LIST(state, payload) {
    // payload.forEach((el) => {
    //   el.name = el.name.toUpperCase();
    // });
    state.tenantsList = payload;
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  ADD_TENANTS(state, payload) {
    state.tenantsList.unshift(payload);
  },

  DELETE_TENANTS(state, payload) {
    state.tenantsList.splice(
      state.tenantsList.map((o) => o.id).indexOf(payload.id),
      1
    );
  },
};

const actions = {
  list: async (store, params) => {
    store.state.isLoading = true;
    return service.list(params).then((data) => {
      store.commit("SET_META", data.body);
      store.commit("SET_TENANTS_LIST", data.body.data);
      store.state.isLoading = false;
      return data.body.data;
    });
  },

  add: (store, params) => {
    return service.add(params).then((data) => {
      store.commit("ADD_TENANTS", data.body);
    });
  },

  update: (store, params) => {
    return service.update(params);
  },

  destroy: (store, params) => {
    return service.destroy(params).then(() => {
      store.commit("DELETE_TENANTS", params);
    });
  },
};

const getters = {
  getTenantsList(state) {
    return state.tenantsList;
  },
  getMeta(state) {
    return state.meta;
  },

  getIsLoading(state) {
    return state.isLoading;
  },
};

const tenantsManagement = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default tenantsManagement;
