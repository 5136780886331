import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios
    .get(`${base_url}battery-diagnostics?imei=${params.imei}`, { params })
    .then((response) => {
      return response.data;
    });
}

async function headers(params) {
  return axios
    .get(`${base_url}battery-telematics/headers-list`, { params })
    .then((response) => {
      return response.data;
    });
}

async function batteryTelematicList(params) {
  return axios
    .get(`${base_url}battery-telematics/${params.bmsId}`, { params })
    .then((response) => {
      return response.data;
    });
}

async function dashboard2w(params) {
  return axios
    .get(`${base_url}battery-diagnostics-dashboard?imei=${params.imei}`, {
      params,
    })
    .then((response) => {
      return response.data;
    });
}

async function dashboard3w(params) {
  return axios
    .get(`${base_url}battery-telematics-dashboard/${params.bmsId}`, { params })
    .then((response) => {
      return response.data;
    });
}

export default {
  list,
  headers,
  batteryTelematicList,
  dashboard2w,
  dashboard3w,
};
