import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios
    .get(`${base_url}vehicle-trips/${params.vehicle_id}`, { params })
    .then((response) => {
      return response.data;
    });
}
async function details(params) {
  return axios
    .get(`${base_url}vehicle-trip-details/${params.id}`, { params })
    .then((response) => {
      return response.data;
    });
}

async function update(params) {
  return axios
    .put(`${base_url}vehicle-trips/${params.id}`, params)
    .then((response) => {
      return response.data;
    });
}

async function updateSOC(params) {
  return axios.post(`${base_url}trip-review`, params).then((response) => {
    return response.data;
  });
}
//
async function tripsDashboard(params) {
  return axios
    .get(`${base_url}vehicle-trips-dashboard`, { params })
    .then((response) => {
      return response.data;
    });
}
async function tripsChart(params) {
  return axios.get(`${base_url}trips-chart`, { params }).then((response) => {
    return response.data;
  });
}

//
async function tripTelematicsHeaders(params) {
  return axios
    .get(`${base_url}trip-telematic-details/${params.tenant_id}/headers-list`, {
      params,
    })
    .then((response) => {
      return response.data;
    });
}

async function tripTelematicsList(params) {
  return axios
    .get(`${base_url}trip-telematic-details`, { params })
    .then((response) => {
      return response.data;
    });
}
export default {
  list,
  details,
  update,
  updateSOC,
  tripsDashboard,
  tripsChart,
  tripTelematicsHeaders,
  tripTelematicsList,
};
