import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return axios.get(`${base_url}roles`, { params }).then((response) => {
    return response.data;
  });
}

async function show(role) {
  return axios.get(`${base_url}roles/${role.id}`).then((response) => {
    return response.data;
  });
}

async function add(role) {
  return axios.post(`${base_url}roles`, role).then((response) => {
    return response.data;
  });
}

async function update(role) {
  return axios.put(`${base_url}roles/${role.id}`, role).then((response) => {
    return response.data.body;
  });
}

async function destroy(role) {
  return axios.delete_method(`${base_url}roles/${role.id}`, role).then((response) => {
    return response.data.body;
  });
}

export default {
  list,
  show,
  add,
  update,
  destroy
};
